<template>
  <div>
    <div v-if="type=='left'" class="titleLent">
      <h4>{{title}}</h4>
      <p>{{describe}}</p>
    </div>

    <div v-if="type=='content'" class="titleCont">
      <h4>{{title}}</h4>
      <p>{{describe}}</p>
      <span></span>
    </div>
  </div>
</template>

<script>
export default {
  props: ['title', 'describe', 'type'],
  data () {
    return {}
  },
  methods: {},
  created () {}
}
</script>
<style lang="less" scoped>
.titleLent {
  height: 60px;
  line-height: 60px;
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  h4 {
    margin: 0;
    font-size: 25px;
    margin-right: 15px;
    color: #171718;
    position: relative;
    height: 100%;
    font-weight: 500;
    &::after {
      content: "";
      display: flex;
      position: absolute;
      bottom: 0;
      width: 40%;
      height: 1px;
      background-color: #171718;
    }
  }
  p {
    color: #797979;
    font-size: 18x;
  }
}
.titleCont {
   text-align: center;
  h4 {
    margin: 0;
    font-size: 25px;
    color: #171718;
    font-weight: 500;
    margin-bottom: 20px;
  }
  p {
    color: #797979;
    font-size: 18px;
    margin-bottom: 15px;
  }
  span{
    display: block;
    width: 50px;
    height: 2px;
    margin:0 auto ;
    background-color: #171718;
  }

}
</style>
