<template>
  <div>
    <div class="topimg">
      <img src="../../../assets/masterStation/04_1.png" alt="">
    </div>

    <div class="list_box">
      <div class="list" v-for="(item,index) in list" :key="index">
        <div class="box item">
          <img :src="item.img" alt="">
          <div class="item_txt">
            <titleTxt :title="item.title" :describe="item.leble" type="left"></titleTxt>
            <div class="itemHtml" v-html="item.html"></div>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import titleTxt from '../component/title.vue'
export default {
  components: { titleTxt },
  data () {
    return {
      list: [
        {
          title: '智慧教育',
          leble: '/WISDON EDUCATION',
          img: require('@/assets/masterStation/图片1.png'),
          html: `<p>柒彩阅卷系统主要分为两个流程阶段，包括有数据采集阶段及数据分析阶段。与传统的阅卷系统相比下，柒彩阅卷系统省去了复杂的基础数据采集过程，
        利用EDU的相关功能直接将考生单位名册、考生名册和阅卷教师名册等置于考试阅卷系统中，从发起考试起直至成绩分析都可以利用edu进行直接体现。</p>
        <p>正通过互联网实现跨地区联盟学校统考的网上阅卷与质量分析，不但解决了集中人工阅卷存在的教师奔波劳累和庞大差旅费支出问题，更重要的是阅卷的质量、
        效率与分析水平得到了极大的提高。以下介绍的是利用互联网实现跨地区联考阅卷及"回家改卷"的基本要求、实现方法及其对促进教学工作模式变革的积极意义。</p>`
        },
        {
          title: '成绩分析系统',
          img: require('@/assets/masterStation/图片2.png'),
          leble: '/WISDON EDUCATION',
          html: `<p>成绩分析系统是一套适合于高中、初中、小学以及教务处对每次考试按年段进行质量分析的软件，该软件操作简便，是依托于学校的考试及诊断分析建立的一款面向家长和学生的个性化学习平台。
        通过收集学生的考试成绩、试题等信息建立学生成长档案，自动归纳和整理学生考试和作业中的错题，利用专业分析精确定位知识漏洞，辅助教师为学生定制个性化提分方案，有效提升学生成绩。</p>`
        },
        {
          title: '教育大数据云平台',
          img: require('@/assets/masterStation/图片3.png'),
          leble: '/WISDON EDUCATION',
          html: `<p>教育大数据云平台是一个综合管理系统，该平台包含了教育教学大数据监管系统、教育工程管理系统、教师人事管理与服务系统、教育督导系统。主要管理系统之间基础数据统一，实现数据互通与同步的效果，
        降低数据录入与数据管理的难度。</p>
        <p>教育工程管理系统：主要是为了响应系统对工程的统一管理，通过对业务工作的基本信息录入，各类资料附件的上传、信息的审核、数据的统计来达到对各部门的业务处理进行管理。</p>
        <p>教师人事管理与服务系统：主要是对全市各类、各级教师的管理，提供对全市各级单位各学校教职工档案信息全面统一的管理。</p>`
        },
        {
          title: '题库系统',
          img: require('@/assets/masterStation/图片4.png'),
          leble: '/WISDON EDUCATION',
          html: `<p>题库系统通过标注题型、答案、解析、知识点、难度、所属试卷、被组卷次数等信息，基于国家教育考试大纲，根据学科特性建设的层级清晰、完备的资源体系。
        题库系统包含试题库和组卷，试题库是按照不同的学科门类及其内在联系，将不同的试题分门别类地汇集起来，为特定学科知识和技能测试提供备选试题的一种系统资源。
        组卷是按照不同的组卷方式将试题组合成测试卷，系统可以实现根据教师端用户选择的组卷维度，运用大数据精准计算引擎推送相应试题，帮助教师高效组卷。
        本系统汇集了中小学各年级段各种试卷、真题、练习题库，涵盖了各个学科试题、试卷，方便各教师、专家参考。</p>`
        },
        {
          title: '财税云系统',
          img: require('@/assets/masterStation/图片5.png'),
          leble: '/WISDON EDUCATION',
          html: `<p>财税云系统围绕遵义市财政工作数据化、信息化、智能化、一体化、现代化的目标，建设遵义市财政数据中心，实现遵义市财政局在财政管理过程中的数据集中化、管控一体化、决策科学化、服务便捷化，建成贵州省财政系统先进的大数据应用服务平台。
        推动大数据在财政领域的探索应用，提升遵义市财政局在财政管理领域的处置能力。 </p>
        <p>建设项目库，以项目库为重点，推动预算评审、绩效评价工作，全面规范预算绩效工作的开展。</p>`
        }
      ]
    }
  },
  methods: {},
  created () {}
}
</script>
<style lang="less" scoped>
.topimg {
  img {
    width: 100%;
  }
}

.list {
  padding: 50px 0;
  .item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    img {
      width: 46%;
    }
    .item_txt {
      width: 46%;
      .itemHtml {
        margin-top: 30px;
        /deep/p {
          margin-bottom: 20px;
          line-height: 30px;
          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &:nth-child(2n) {
    background-color: #f9f9f9;
    .item {
      flex-direction: row-reverse;
    }
  }
  &:last-child{
    padding-bottom: 80px;
  }
}
</style>
